import * as cn from 'classnames';
import { chain, filter, isEmpty, uniqueId } from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import DropdownToolbox from "react-toolbox/lib/dropdown";
import { State } from "../../reducers";
import { attemptInvoke, isEqualByPropertiesLabelValue } from "../../utils/ReactHelpers";

const baseTheme = require("./baseTheme.scss");
const CSS_CLASSES: { [key: string]: any[] } = {
    'small': [baseTheme.template, baseTheme.small],
    'normal': []
}

type Item = {
    label: string;
    value: string;
    // additionals:
    platform: string;
}

interface DispatchToProps { }
function mapDispatchToProps(dispatch: any) { return {} }

interface StateToProps {
    allPlatformItems: Item[];
}
function mapStateToProps(state: State.All): StateToProps {
    const allPlatformItems = chain(state?.organization?.selectedOrganization?.virtualDevices)
        .filter(({ status }) => status !== "disabled")
        .map(({ name: label, token: value, platform }) => ({ value, label, platform }))
        .value()

    return {
        allPlatformItems
    };
}

interface ExposedProps {
    platform: string
    onValueChanged: (token: Item) => void;
    selectedValue: string;
    size?: 'small' | 'normal';
    selectFirstIfNotFound: boolean;
    ifNotItems?: JSX.Element
}
function mergeProps(ownProps: any, stateProps: any, dispatchProps: ExposedProps) {
    return { ...ownProps, ...stateProps, ...dispatchProps }
}

interface ComponentProps extends DispatchToProps, StateToProps, ExposedProps {
    disabled?: boolean;
}

interface ComponentState {
    items: Item[];
    selectedValue: string;
}

class VirtualDeviceDropdownComponent extends React.Component<ComponentProps, ComponentState> {

    static defaultProps: ComponentProps = {
        allPlatformItems: [],
        disabled: false,
        selectedValue: undefined,
        platform: undefined,
        onValueChanged: () => { },
        size: 'normal',
        selectFirstIfNotFound: false
    }

    constructor(props: ComponentProps) {
        super(props);
        this.state = {
            items: [],
            selectedValue: undefined
        }
    }

    componentWillMount(): void {
        const { selectedValue: initialValue, allPlatformItems, platform, selectFirstIfNotFound } = this.props

        const items = chain(allPlatformItems)
            .thru(items => {
                if (isEmpty(platform)) { return items }
                return filter(items, it => it.platform === platform)
            })
            .defaultTo([])
            .value()

        const selectedValue = chain(items)
            .defaultTo(this.state.items)
            .map(({ value }) => value)
            .filter(value => value === initialValue)
            // selects first if initialValue is not found
            .thru(all => {
                if (isEmpty(all) && selectFirstIfNotFound) {
                    return chain(items)
                        .defaultTo(this.state.items)
                        .slice(0, 1)
                        .map(({ value }) => value)
                        .value()
                }
                return all;
            })
            .first()
            .value()

        this.setState({
            items,
            selectedValue
        }, () => {
            const selectedItem: Item = chain(this.state.items).find(it => it.value === selectedValue).clone().value()
            attemptInvoke(this.props.onValueChanged, selectedItem)
        })
    }

    componentWillReceiveProps(nextProps: Readonly<ComponentProps>, nextContext: any): void {
        const flags = {
            updateItems: false,
            updateSelected: false
        }

        if (nextProps.platform !== this.props.platform) {
            flags.updateItems = true;
            flags.updateSelected = true;
        }

        if (nextProps.selectedValue !== this.props.selectedValue) {
            flags.updateSelected = true;
        }

        if (!isEqualByPropertiesLabelValue(nextProps.allPlatformItems, this.props.allPlatformItems)) {
            flags.updateItems = true;
            flags.updateSelected = true;
        }

        if (!chain(flags).values().some(f => f).value()) { return }

        const newState: ComponentState = {} as any

        if (flags.updateItems) {
            newState.items = chain(nextProps.allPlatformItems)
                .thru(items => {
                    if (isEmpty(nextProps.platform)) { return items }
                    return filter(items, it => it.platform === nextProps.platform)
                })
                .defaultTo([])
                .value()
        }

        if (flags.updateSelected) {
            newState.selectedValue = chain(newState.items)
                .defaultTo(this.state.items)
                .map(({ value }) => value)
                .filter(value => value === nextProps.selectedValue)
                // selects first if initialValue is not found
                .thru(all => {
                    if (isEmpty(all) && this.props.selectFirstIfNotFound) {
                        return chain(newState.items).defaultTo(this.state.items).slice(0, 1).map(({ value }) => value).value()
                    }
                    return all;
                })
                .first()
                .value()

        }


        this.setState({ ...newState }, () => {
            const newSelectedValue = newState.selectedValue || this.state.selectedValue
            const selectedItem: Item = chain(this.state.items).find(it => it.value === newSelectedValue).clone().value()
            attemptInvoke(this.props.onValueChanged, selectedItem)
        })
    }

    render(): false | JSX.Element {
        if (isEmpty(this.state.items) && !isEmpty(this?.props?.ifNotItems)) {
            return this?.props?.ifNotItems
        }

        // Using random id to force close popup when value changed
        const uniqId = uniqueId()
        return (
            <DropdownToolbox
                key={`virtual-device-dropdown-${uniqId}`}
                theme={baseTheme}
                disabled={this.props?.disabled}
                className={cn(CSS_CLASSES[this.props?.size])}
                onChange={(value: any) => {
                    const selectedItem: Item = chain(this.state.items).find(it => it.value === value).clone().value()
                    this.setState({ selectedValue: value }, () => attemptInvoke(this.props.onValueChanged, selectedItem))
                }}
                source={this.state?.items}
                value={this.state?.selectedValue} />
        )
    }
}

export const VirtualDeviceDropdown = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(VirtualDeviceDropdownComponent);

import * as React from "react";
import { connect } from "react-redux";
import { replace } from "react-router-redux";
import Snackbar from "react-toolbox/lib/snackbar";
import { setLoading } from "../../actions/loading";
import { Loader } from "../../components";
import { State } from "../../reducers";
import auth from "../../services/auth";
import * as Intercom from "../../services/intercom";
import FirstStep from "./FirstStep";
import SecondStep from "./SecondStep";
import { defaultTo, isEmpty, trim } from "lodash";
import UtilsServer from "../../services/utils-server";
import { User } from "../../reducers/user";
import { createOrganization, fetchOrganization } from "../../actions/organization";
import { AuthUser } from "../../reducers/context";
import { wrapCallbackAsAsync } from "../../utils/ReactHelpers";
import { fetchUser } from "../../actions/user";
import { loginWithIbm } from "../../actions/context";

const style = require("./surveyStyles.scss");

interface SurveyVerifyProps {
    authUser: AuthUser;
    user: User;
    goTo: (path: string) => (dispatch: any) => void;
    setLoading: (value: boolean) => (dispatch: any) => void;
    loading: boolean;
    createOrganization: (user: User) => Promise<any>;
    fetchUser: () => Promise<any>;
    fetchOrganization: (organizationId: string) => Promise<any>;
    loginWithIbm: (instanceId: string, code: string) => Promise<any>;
}

interface SurveyVerifyStates {
    step: 1 | 2;
    data: any;
    showSnackbar: boolean;
    snackbarMessage: string;
    showLoader: boolean;
}

const mapStateToProps = (state: State.All) => ({
    authUser: state.context.authUser,
    user: defaultTo(state.user.currentUser, {}),
    loading: state.loading.loading,
});

const mapDispatchToProps = (dispatch: any) => ({
    goTo: (path: string) => {
        dispatch(replace(path));
    },
    setLoading: (value: boolean) => {
        return dispatch(setLoading(value));
    },
    loginWithIbm: (instanceId: string, code: string) =>
        wrapCallbackAsAsync(handle => dispatch(loginWithIbm(instanceId, code, handle))),
    createOrganization: (user: User) =>
        wrapCallbackAsAsync(handle => dispatch(createOrganization(user, handle))),
    fetchUser: () => wrapCallbackAsAsync(handle => dispatch(fetchUser(handle))),
    fetchOrganization: (organizationId: string) =>
        wrapCallbackAsAsync(handle => dispatch(fetchOrganization(organizationId, handle))),

});

const globalWindow: any = typeof (window) !== "undefined" ? window : {};

// TODO rework on boarding
export class Survey extends React.Component<SurveyVerifyProps, SurveyVerifyStates> {
    constructor(props: SurveyVerifyProps) {
        super(props);
        this.state = {
            step: 1,
            data: {},
            showSnackbar: false,
            snackbarMessage: "",
            showLoader: false
        };
    }
    componentDidMount() {
        // console.log('Survey:componentDidMount', this.props, this.props.authUser)
    }
    componentWillReceiveProps(nextProps: SurveyVerifyProps, context: any) {
        // console.log('Survey:componentWillReceiveProps', nextProps, this.props)
    }
    handleOnStepChange = async (dataFromFirstStep: any) => {
        this.setState(prevState => ({
            ...prevState,
            data: dataFromFirstStep, step: 2,
        }));
        const { onboardingData } = this.props?.user || {}
        const ibmUser = !isEmpty(onboardingData)

        const { name, userCompany, jobTitle, email } = dataFromFirstStep;
        globalWindow && Intercom.updateIntercom(globalWindow, {
            name,
            // userCompany,
            jobTitle,
            email,
            ibmUser,
            userWasInvited: false,
        }, email);

        const currentUserEmail = email || auth.getCurrentUserEmail();
        UtilsServer.generateBespokenLead(name, '', currentUserEmail, userCompany)
    }

    submitSurvey = async (dataFromSecondStep: any) => {
        const data = { ...this.state.data, ...dataFromSecondStep };
        const { name, userCompany: company, jobTitle, email, ...surveyData } = data;
        const newsLetter: boolean = data.isNewsLetterChecked;
        const isIbmUser = !isEmpty(this.props?.user?.onboardingData?.ibm)

        const updateEmailIfIbmUser = isIbmUser ? { email } : {}

        try {
            this.props.setLoading(true);
            const updatedProps: User = {
                id: this.props.authUser.id,
                name,
                onboardingData: {
                    organizationName: company
                },
                jobTitle,
                surveyData,
                surveyCompleted: true,
                ...updateEmailIfIbmUser
            };

            const result = await this.props.createOrganization(updatedProps);



            if (isIbmUser) {
                const instanceId = this.props?.user?.onboardingData?.ibm?.instanceId;

                const loginWithIbmResult = await this.props.loginWithIbm(instanceId, '');
                this.props.goTo("/");
                window.location.reload();
                return
            }

            const organizationIdOrDefault = result?.id || 'default'
            const fetchUserResult = await this.props.fetchUser();
            const fetchOrganizationResult = await this.props.fetchOrganization(organizationIdOrDefault)

            if (fetchUserResult?.userRequiresActivation) {
                this.props.goTo("/needsActivation");
            } else {
                this.props.goTo("/skills");
            }
            this.props.setLoading(false);
        } catch (error) {
        }
    }
    handleSnackbarClick = async () => {
        await this.setState({ ...this.state, showSnackbar: false });
    }
    changeSnackMessage = (snackbarMessage: string) => {
        this.setState((prevState) => ({
            ...prevState,
            showSnackbar: true,
            snackbarMessage
        }));
    }

    render() {
        const { step, data } = this.state;
        const email = this.props.user?.email || data?.email;
        if (step === 1) {
            return <FirstStep onStepChange={this.handleOnStepChange} user={this.props.user} />;
        }
        if (step === 2) {
            return <div className={"secondStep"}>
                <SecondStep
                    user={this.props.user}
                    onStepChange={this.submitSurvey}
                    email={email}
                    changeSnackMessage={this.changeSnackMessage}
                    setLoading={this.props.setLoading} />
                <Snackbar className="sm-snackbar"
                    action="Dismiss"
                    type="cancel"
                    active={this.state.showSnackbar}
                    label={this.state.snackbarMessage}
                    onClick={this.handleSnackbarClick}
                    timeout={10000}
                    onTimeout={this.handleSnackbarClick} />
                {this.props.loading && <Loader />}
            </div>;
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Survey);
